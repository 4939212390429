.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
.button {
  background-color: transparent;
  line-height: 1.44444444;
  cursor: pointer;
  color: #868686;
  padding: 11px 46px 11px 18px;
  -webkit-appearance: none;
  border-radius: 4px;
  box-sizing: border-box;
  text-decoration: none;
  text-align-last: left;
  border: 1px solid #868686;
  min-width: 200px;
  transition: all 0.3s;
  background-size: 10px 18px;
  background-position: right 18px center;
  background-repeat: no-repeat;
  background-image: url(/images/corner-right-gray.svg);
}
.button:hover,
.button:focus {
  color: #000;
  background-color: #C8EA8E;
  border-color: #C8EA8E;
  background-image: url(/images/corner-right-black.svg);
}
@media (max-width: 1023px) {
  .button {
    min-width: 140px;
  }
}
div.navi {
  float: left;
}
div.navi > .item {
  float: left;
}
div.navi > .item > .menu {
  display: block;
}
@media (min-width: 1024px) {
  .togglenavigation,
  .mobile-navigation {
    display: none;
  }
}
.servicenavi div.sub1 > .item {
  margin: 0 20px;
}
.servicenavi div.sub1 > .item.init {
  margin-left: 0;
}
.servicenavi div.sub1 > .item.exit {
  margin-right: 0;
}
.servicenavi div.sub1 > .item > .menu {
  font-size: 16px;
  line-height: 1.25;
  letter-spacing: 0.01em;
  color: #868686;
}
.servicenavi div.sub1 > .item > .menu:hover,
.servicenavi div.sub1 > .item > .menu:focus {
  color: #000;
}
.servicenavi div.sub1 > .item > .menu.path {
  color: #000;
  font-weight: 500;
}
.mainnavi div.sub1 {
  margin: 19px 0;
}
.mainnavi div.sub1 > .item {
  margin: 0 25px;
}
.mainnavi div.sub1 > .item.init {
  margin-left: 0;
}
.mainnavi div.sub1 > .item.exit {
  margin-right: 0;
}
.mainnavi div.sub1 > .item > .menu {
  color: #000;
  font-size: 20px;
  line-height: 1.5;
  letter-spacing: 0.01em;
  position: relative;
}
.mainnavi div.sub1 > .item > .menu:before {
  content: '';
  position: absolute;
  left: -5px;
  bottom: 0;
  width: 0;
  height: 10px;
  background-color: #C8EA8E;
  z-index: -1;
  transition: all 0.3s;
}
.mainnavi div.sub1 > .item > .menu:hover:before,
.mainnavi div.sub1 > .item > .menu:focus:before,
.mainnavi div.sub1 > .item > .menu.path:before {
  width: calc(100% + 10px);
}
.desk {
  max-width: calc(100% - 80px);
}
.section--one .desk {
  width: 872px;
}
.footpart--verband {
  width: 300px;
  margin-left: auto;
  margin-top: 22px;
  padding-bottom: 30px;
}
@media (max-width: 1245px) {
  .footpart--verband {
    margin-top: 50px;
    margin-left: 0;
  }
}
.footlogo--verband {
  margin-left: auto;
}
@media (max-width: 1245px) {
  .footlogo--verband {
    margin-left: -42px;
  }
}
.headercontent {
  align-items: flex-end;
}
.section--two .area .pure.wide div.line {
  margin-top: 65px;
  margin-bottom: 65px;
}
.section--two .area .fold + .fold {
  margin-top: -26px;
}
.section--two .area {
  width: 100%;
}
.section--two .area > .unit {
  margin-right: 2.46305419%;
  margin-left: 2.46305419%;
  width: 95.07389163%;
}
.section--two .area .cb-album .body,
.section--two .area .head,
.section--two .area .foot,
.section--two .area .part {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.section--two .area > .slim {
  width: 45.07389163%;
}
.section--two .area > .slim .head,
.section--two .area > .slim .foot,
.section--two .area > .slim .part {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.section--two .area > .slim.cb-album .body {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.section--two .area > .slim .tiny {
  width: 100%;
}
.section--two .area {
  width: 105.18134715%;
  margin-left: -2.59067358%;
}
.section--two .area .pure.wide .body {
  width: 105.18134715%;
  margin-left: -2.59067358%;
}
.section--two .area .pure.wide .part {
  margin-left: 2.46305419%;
  margin-right: 2.46305419%;
  width: 95.07389163%;
}
.section--two .area .pure.wide .part.tiny {
  width: 45.07389163%;
}
.section--two .area .seam {
  width: 28.40722496%;
}
.section--two .area .fold {
  width: 95.07389163%;
}
/*# sourceMappingURL=./screen-large.css.map */